import { render, staticRenderFns } from "./RomanLegionModal.vue?vue&type=template&id=5c0aae0f&scoped=true&"
import script from "./RomanLegionModal.vue?vue&type=script&lang=js&"
export * from "./RomanLegionModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c0aae0f",
  null
  
)

export default component.exports